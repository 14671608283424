export const defaultTheme = {
  colors: {
    primaryMain: '#1E4A91',
    primaryLight: '#EBF3FF',
    primaryLighter: '#F1F7FF',
    primaryLightest: '#F9FAFE',
    primaryDarker: '#0C1E3A',

    secondaryPink: '#D41367',
    secondaryPinkLight: '#E571A4',
    secondaryPinkLighter: ' #F3BFD6',
    secondaryPinkDarker: '#6A0A34',

    accentRed: '#FF4D4F',
    accentYellow: '#FFDE69',
    accentOrange: '#FF9700',
    accentGreen: '#64C635',
    accentBlue: '#1890FF',
    accentPurple: '#A93495',
    accentRedLight: '#FFF1F1',
    accentGreenLight: '#F3FBEF',

    neutralMain: '#262626',
    neutralDarker: '#8C8C8C',
    neutralLighter: '#CCCCCC',
    neutralSubtle: '#EAEAEA',
    neutralSubtler: '#FAFAFA',
    neutralWhite: '#FFFFFF',
  },
};

export const themes = () => ({
  token: {
    colorPrimary: defaultTheme.colors.secondaryPink,
    borderRadius: 2,
  },
  components: {
    Menu: {
      itemBg: defaultTheme.colors.primaryMain,
      itemColor: defaultTheme.colors.neutralMain,
      itemHoverColor: defaultTheme.colors.secondaryPink,
      itemSelectedColor: defaultTheme.colors.secondaryPink,
      itemSelectedBg: defaultTheme.colors.neutralWhite,
      horizontalItemSelectedColor: defaultTheme.colors.secondaryPinkLighter,
      subMenuItemBg: defaultTheme.colors.neutralWhite,
    },
    Layout: {
      colorBgBody: defaultTheme.colors.primaryLightest,
    },
    Table: {
      tableHeaderBg: defaultTheme.colors.accentRed,
    },
    Switch: {
      colorPrimary: defaultTheme.colors.accentGreen,
      colorPrimaryHover: defaultTheme.colors.neutralLighter,
    },
    Select: {
      colorTextPlaceholder: defaultTheme.colors.neutralMain,
    },
    UserOverview: {
      mainActionButtons: defaultTheme.colors.primaryMain,
      userStatusPending: defaultTheme.colors.accentRed,
      userStatusRegistered: defaultTheme.colors.accentGreen,
      userStatusUnknown: defaultTheme.colors.neutralLighter,
    },
    AddUsers_AssignmentTabs: {
      colorText: defaultTheme.colors.neutralDarker,
    },
  },
});
