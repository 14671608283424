import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import app from './reducers/app';

const formData = (state = {}, action = {}) => {
  switch (action.type) {
    case 'LOAD':
      return { ...state, [action.formName]: action.data };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  form: formReducer,
  app,
  loadedForm: formData,
});

export default rootReducer;
