/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/**
 * LOCAL STORAGE PERSISTENCE
 */
const { localStorage } = window;
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
const lzString = require('lz-string');

export class BackgroundQueryStorage {
  static getStoredKeys() {
    const keys = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith('__BackgroundQueryStorage.')) keys.push(key);
    }

    return keys;
  }

  static getStoredCache() {
    const cache = {};
    BackgroundQueryStorage.getStoredKeys().forEach((key) => {
      cache[BackgroundQueryStorage.getUUIDFromStorageKey(key)] = BackgroundQueryStorage.getRaw(key);
    });

    return cache;
  }

  static purge() {
    BackgroundQueryStorage.getStoredKeys().forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  static getUUIDFromStorageKey(storageKey) {
    return storageKey.replace('__BackgroundQueryStorage.', '');
  }

  static getStorageKey(UUID) {
    return `__BackgroundQueryStorage.${UUID}`;
  }

  static getRaw(key) {
    return BackgroundQueryStorage._decompress(localStorage.getItem(key));
  }

  static get(UUID) {
    try {
      // console.log('BackgroundQueryStorage:', 'get', UUID);
      return BackgroundQueryStorage.getRaw(BackgroundQueryStorage.getStorageKey(UUID));
    } catch (err) {
      // console.error(err);
      return undefined;
    }
  }

  static _compress(payload) {
    // return JSON.stringify(payload);
    return lzString.compress(JSON.stringify(payload));
  }

  static _decompress(payload) {
    // return JSON.parse(payload);
    return JSON.parse(lzString.decompress(payload));
  }

  static set(UUID, payload) {
    try {
      // console.log('BackgroundQueryStorage:', 'set', UUID);
      localStorage.setItem(BackgroundQueryStorage.getStorageKey(UUID), BackgroundQueryStorage._compress(payload));
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  static remove(UUID) {
    // console.log('BackgroundQueryStorage:', 'remove', UUID);
    localStorage.removeItem(BackgroundQueryStorage.getStorageKey(UUID));
  }
}
