/* eslint-disable import/no-extraneous-dependencies */
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Session from 'supertokens-auth-react/recipe/session';
/* eslint-disable  import/no-unresolved */
import jwt from 'jsonwebtoken';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVICE_URL || 'https://staging.wapa.lizard.global/graphql',
});

const noAuthQueries = ['GetPasswordHistory'];

const authLink = setContext(async (request, { headers }) => {
  if (noAuthQueries.includes(request.operationName)) {
    // Don't need access token for these queries
    return {
      headers: {
        ...headers,
      },
    };
  }
  let accessToken = (await Session.doesSessionExist())
    ? await Session.getAccessToken()
    : {};

  const decodedToken = jwt.decode(accessToken);
  console.log(decodedToken, 'decodedToken');

  if (decodedToken?.exp <= ((new Date().getTime() - 2000) / 1000)) {
    await Session.attemptRefreshingSession();
    accessToken = await Session.getAccessToken();
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
