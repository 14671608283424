import Session, { BooleanClaim } from 'supertokens-auth-react/recipe/session';

const SecondFactorClaim = new BooleanClaim({
  id: '2fa-completed',
  refresh: async () => {
    // This is something we have no way of refreshing, so this is a no-op
  },
  onFailureRedirection: () => '/second-factor',
});

async function shouldShowFirstFactor() {
  return !(await Session.doesSessionExist());
}

async function shouldShowSecondFactor() {
  if (await shouldShowFirstFactor()) {
    return false;
  }

  return !(await Session.getClaimValue({ claim: SecondFactorClaim }));
}

export { shouldShowFirstFactor, shouldShowSecondFactor, SecondFactorClaim };
