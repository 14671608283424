import DefaultLayout from './DefaultLayout';
import AuthLayout from './AuthLayout';
import TfaLayout from './TfaLayout';

const layouts = {
  DefaultLayout,
  AuthLayout,
  TfaLayout,
};

export default layouts;
