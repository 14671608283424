import { gql } from '@apollo/client';

export const GET_POLICIES = gql`
  query GetPolicies(
    $paginator: Paginator!
    $sorter: Sorter!
    $filters: PolicyFilters!
    $search: String
  ) {
    getPolicies(
      paginator: $paginator
      sorter: $sorter
      filters: $filters
      search: $search
    ) {
      data {
        id
        name
        status
        fleet
        vesselCount
        dateFrom
        dateTo
        timezone
      }
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
    }
  }
`;

export const GET_POLICY = gql`
  query GetPolicy($getPolicyId: ID!) {
    getPolicy(id: $getPolicyId) {
      data {
        id
        name
        status
        fleet
        group
        dateFrom
        dateTo
        timezone
        policyId
        documents {
          filename
          path
          uid
        }
        underwriter {
          isLead
          name
          percentage
        }
        ice_class
      }
    }
  }
`;

export const CREATE_POLICY = gql`
  mutation CreatePolicy($policyDetails: PolicyInput!) {
    createPolicy(policyDetails: $policyDetails) {
      message
    }
  }
`;

export const UPDATE_POLICY = gql`
  mutation UpdatePolicy($updatePolicyId: ID!, $policyDetails: PolicyInput!) {
    updatePolicy(id: $updatePolicyId, policyDetails: $policyDetails) {
      message
    }
  }
`;

export const REMOVE_POLICY = gql`
  mutation RemovePolicy($removePolicyId: ID!) {
    removePolicy(id: $removePolicyId) {
      message
    }
  }
`;

const GET_POLICY_EXPORT_INFO = gql`
  query GetPolicyExportInfo($policyId: ID!) {
    getPolicyExportInfo(policyId: $policyId) {
      exportInfo {
        name
        voyagesCount
        gross
        discount
        net
        commission
        brokerCommission
        netCommission
        netPremium
        dateFrom
        dateTo
        underwriter {
          name
          percentage
          isLead
        }
      }
      status
      message
    }
  }
`;

export { GET_POLICY_EXPORT_INFO };
