import { gql } from '@apollo/client';

const GET_FLEETS = gql`
  query getAllFleets {
    getFleets {
      data {
        id
        name
        acquisition
        vesselIds
        policyCount
        vesselCount
      }
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
      status
      message
    }
  }
`;

const GET_FLEET = gql`
  query GetFleet($getFleetId: ID!) {
    getFleet(id: $getFleetId) {
      data {
        id
        name
        acquisition
        vesselIds
        policies {
          id
          name
          status
          fleet
          group
          dateFrom
          dateTo
          timezone
          policyId
          documents {
            filename
            path
          }
          underwriter {
            name
            percentage
            isLead
          }
          ice_class
        }
      }
      status
      message
    }
  }
`;

const GET_FLEETS_FOR_USERS_OVERVIEW = gql`
  query GetFleetsForUsers {
    getFleets {
      data {
        name
        id
      }
      message
      status
    }
  }
`;

const CREATE_FLEET = gql`
  mutation CreateFleet($fleetDetails: FleetInput!) {
    createFleet(fleetDetails: $fleetDetails) {
      message
      status
    }
  }
`;

const UPDATE_FLEET = gql`
  mutation UpdateFleet($updateFleetId: ID!, $fleetDetails: FleetInput!) {
    updateFleet(id: $updateFleetId, fleetDetails: $fleetDetails) {
      message
      status
    }
  }
`;

const GET_VESSELS = gql`
  query GetVessels {
    getVessels {
      data {
        id
        name
        IMO
        type
        fleet
        owner
        flag
        gt
        dwt
        year
        bareboatCharterer
        callSign
        class
        iceClass
        currency
        digest
        insurance {
          type
          amount
          applicableFrom
        }
        conditions {
          type
          value
        }
      }
      message
      status
    }
  }
`;

const UPDATE_VESSEL = gql`
  mutation UpdateVessel($updateVesselId: ID!, $vesselDetails: VesselInput!) {
    updateVessel(id: $updateVesselId, vesselDetails: $vesselDetails) {
      status
      message
    }
  }
`;

const REMOVE_VESSEL = gql`
  mutation RemoveVessel($removeVesselId: ID!) {
    removeVessel(id: $removeVesselId) {
      message
      status
    }
  }
`;

const REMOVE_FLEET = gql`
  mutation RemoveFleet($removeFleetId: ID!) {
    removeFleet(id: $removeFleetId) {
      message
      status
    }
  }
`;

export {
  GET_FLEETS,
  GET_FLEET,
  GET_VESSELS,
  REMOVE_FLEET,
  UPDATE_VESSEL,
  CREATE_FLEET,
  REMOVE_VESSEL,
  UPDATE_FLEET,
  GET_FLEETS_FOR_USERS_OVERVIEW,
};
