/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useContext,
  createContext,
  useEffect,
  useState,
} from 'react';
import Session, { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { signOut } from 'supertokens-web-js/recipe/emailpassword';
import { useLazyQuery } from '@apollo/client';
import { notification } from 'antd';
import { shouldShowFirstFactor, shouldShowSecondFactor } from '../utils/supertokensClaims';
import { UserGQL } from '../services/gqls';
import { SystemError } from '../utils/error';
import { defaultRolePermissions } from '../utils/enums';
import getQueryErrorMessage from '../utils/getQueryErrorMessage';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const session = useSessionContext();
  const [firstFactor, setFirstFactor] = useState(true);
  const [secondFactor, setSecondFactor] = useState(true);
  const [mfaSetup, setMfaSetup] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [profile, setProfile] = useState({
    loading: true, // used for final profile data loading. Will be removed when data is populated
    uid: '',
    fullname: '',
    firstname: '',
    lastname: '',
    email: '',
    role: 'guest',
    assignedFleet: [],
    assignedGroup: [],
    assignedVessel: [],
    digest: false,
    report: false,
    preferences: {
      acquisitionMode: false,
      dashboardGroupSelection: [],
    },
    MFA: {
      key: '',
      recoveryKeys: [],
      imageUrl: '',
      setupCompleted: false,
    },
  });
  const [getUserProfile, {
    loading: getUserProfileLoading,
    called: getUserProfileCalled,
  }] = useLazyQuery(UserGQL.GET_PROFILE, {
    onCompleted: ({ getProfile }) => {
      if (getProfile?.data) {
        setProfile(getProfile.data);
      } else {
        console.log(getProfile);
      }
    },
    onError: (error) => {
      if (error.networkError.result.errors[0].message === 'Session revoked') {
        notification.error({
          message: 'Session revoked',
          description: 'Your current session has been revoked. You need to login again. You will be redirected to the login page shortly.',
        });
        setTimeout(() => {
          // eslint-disable-next-line no-use-before-define
          onLogout();
        }, 2000);
      } else {
        console.error(error); // eslint-disable-line no-console
        SystemError(error);
        setErrorOccured(true);
      }
    },
  });

  useEffect(() => {
    const checkForSession = async () => {
      const loginCompleted = await shouldShowFirstFactor();
      setFirstFactor(loginCompleted);
      const mfaCompleted = await shouldShowSecondFactor();
      setSecondFactor(mfaCompleted);
    };

    const fetchUserData = async () => {
      console.log('fetching user data...');
      const results = await getUserProfile({
        fetchPolicy: 'no-cache',
      });
      const errorMessage = getQueryErrorMessage(results);
      if (errorMessage) {
        console.error(errorMessage, results);
        SystemError(errorMessage);
        setErrorOccured(true);
      }
      console.log('fetched user data', errorMessage, results);
    };

    checkForSession();
    if (session.doesSessionExist) {
      fetchUserData();
    } else if (session.doesSessionExist === false) {
      setProfile((prev) => ({
        ...prev,
        loading: false,
      }));
    }
    if (session?.accessTokenPayload?.MFA) {
      setMfaSetup(true);
    }
  }, [session]);

  const clearUserSession = async () => {
    await signOut();
    localStorage.clear();
  };
  const onLogout = async () => {
    clearUserSession();
  };

  // workaround for 2FA, get updated session claims after 2FA completed
  const getNewSessionClaims = async () => {
    await Session.attemptRefreshingSession();
  };

  const isUserProfileLoading = (
    session.doesSessionExist ? (
      getUserProfileLoading || !getUserProfileCalled
    ) : false
  );

  // function to fetch newest data for getProfile and return the result
  const fetchUserProfile = async () => {
    console.log('fetching user data...');
    const results = await getUserProfile({
      fetchPolicy: 'no-cache',
    });
    const errorMessage = getQueryErrorMessage(results);
    if (errorMessage) {
      console.error(errorMessage, results);
      SystemError(errorMessage);
      setErrorOccured(true);
    }
    console.log('fetched user data', errorMessage, results);
  };

  return (
    <AuthContext.Provider
      value={{
        loading: Boolean(profile.loading || session?.loading || isUserProfileLoading),
        errorOccured,
        firstFactor,
        secondFactor,
        profile,
        setProfile,
        preferences: profile.preferences,
        permissions: defaultRolePermissions[profile.role || 'guest'],
        mfaSetup,
        onTFAValidated: () => getNewSessionClaims(),
        onLogout,
        fetchUserProfile,
      }}
    >
      {children}

    </AuthContext.Provider>
  );
};

export default AuthProvider;
export { AuthContext };
