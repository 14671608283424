import React, { useContext, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import {
  Button, Dropdown, Layout, Menu, Popover, Switch,
} from 'antd';
import {
  CloseOutlined,
  DownOutlined,
  MoreOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { pathOr } from 'ramda';
import { useMutation } from '@apollo/client';
import styles from './header.module.scss';
import Logo from '../../images/wapa-logo.png';
import UserIcon from '../../images/user-icon.svg';
import UserIconActive from '../../images/user-icon-active.svg';
import routes from './routes';
import { AuthContext } from '../../contexts/AuthProvider';
import { UPDATE_USER_PREFERENCES } from '../../services/gqls/user';
import { defaultRolePermissions } from '../../utils/enums';
import { BackgroundQueryCoordinator, BackgroundQueryFlags } from '../../utils/hooks/BackgroundQueryCoordinator';

const { Header: AntHeader } = Layout;

const Header = () => {
  const location = useLocation();
  const {
    onLogout, preferences, setProfile, profile,
  } = useContext(AuthContext);
  const [openKeys, setOpenKeys] = useState([]);
  const [updateUserPreferences] = useMutation(UPDATE_USER_PREFERENCES);
  const [accountMenuOpen, setAccountMenuOpen] = useState(false);

  const onToggleAcquisitionMode = async (checked) => {
    await updateUserPreferences({
      variables: {
        preferences: {
          acquisitionMode: checked,
        },
      },
    });
    BackgroundQueryCoordinator.expiredFlag([
      BackgroundQueryFlags.FLEET,
      BackgroundQueryFlags.VESSEL,
    ]);

    setProfile((prev) => ({
      ...prev,
      preferences: {
        ...prev.preferences,
        acquisitionMode: checked,
      },
    }));
  };

  const getSelectedKey = () => {
    const { pathname } = location;
    if (pathname === '/' || pathname === '/dashboard') {
      return ['map'];
    }
    return [`${pathname.split('/')[1]}`];
  };

  const items = [
    {
      label: <Link to="/useraccount">Account settings</Link>,
      key: '0',
    },
    {
      label: (
        <Button className={styles.signOutBtn} type="ghost" onClick={onLogout}>
          Sign out
        </Button>
      ),
      key: '1',
    },
  ];

  const getLink = (path) => `/${path.split('/')[1]}`;

  // reload page on menu item click
  const handleLinkReload = (path) => {
    const selectedPath = getLink(path);
    const currentLocation = location.pathname.split('/')[1];
    if (currentLocation === selectedPath.split('/')[1]) {
      window.location.replace(selectedPath);
    }
  };

  // const oldRolename = roles.filter((role) => profile.role === role.keyNew)[0].key;
  const permissions = defaultRolePermissions[profile.role];
  const checkPermissions = (route) => {
    console.log('app:', route, permissions);
    if (route && pathOr(true, [route.key, 'read'], permissions)) return true;
    return false;
  };

  const injectLinktoRoutes = () => {
    const routesWithLink = routes.filter(checkPermissions).map((route) => {
      if (route.children) {
        return {
          ...route,
          popupOffset: [-8, -3],
          label: (
            <div className={styles.menuTitleWrapper}>
              <span className={styles.menuTitle}>{route.label}</span>
              {openKeys.includes(route.key) ? (
                <UpOutlined style={{ fontSize: 8, marginTop: 1 }} />
              ) : (
                <DownOutlined style={{ fontSize: 8, marginTop: 1 }} />
              )}
            </div>
          ),
          children: route.children.filter(checkPermissions).map((elem) => ({
            key: elem.key,
            label: (
              <Link
                to={getLink(elem.path)}
                onClick={() => handleLinkReload(elem.path)}
              >
                <span style={{ fontFamily: 'Lato Semibold' }}>
                  {elem.label}
                </span>
              </Link>
            ),
          })),
        };
      }
      return {
        key: route.key,
        label: (
          <Link
            to={getLink(route.path)}
            onClick={() => handleLinkReload(route.path)}
          >
            {route.label}
          </Link>
        ),
      };
    });
    return routesWithLink;
  };

  return (
    <AntHeader
      className={
        preferences?.acquisitionMode
          ? `${styles.main} ${styles.mainDark}`
          : `${styles.main}`
      }
    >
      <div
        className={
          preferences?.acquisitionMode
            ? `${styles.logoWrapper} ${styles.logoDark}`
            : `${styles.logoWrapper}`
        }
      >
        <img alt="WAPA Logo" width={42} height={36} src={Logo} />
      </div>
      <Menu
        className={
          preferences?.acquisitionMode
            ? `${styles.headerMenu} ${styles.headerDark}`
            : `${styles.headerMenu}`
        }
        mode="horizontal"
        items={injectLinktoRoutes()}
        openKeys={openKeys}
        onOpenChange={(keys) => setOpenKeys(keys)}
        selectedKeys={getSelectedKey()}
      />
      <div className={styles.accountMenu}>
        {preferences?.acquisitionMode ? (
          <div className={styles.acquisitionModeDiv}>
            <span>Acquisition mode ON</span>

            <CloseOutlined
              onClick={() => onToggleAcquisitionMode(false)}
              style={{ fontSize: 14, color: '#8C8C8C', cursor: 'pointer' }}
            />
          </div>
        ) : permissions?.voyages.create && (
          <div className={styles.createVoyageHeaderButton}>
            <Button
              type="primary"
              onClick={() => {
                window.location.replace('/voyages/new');
              }}
            >
              Create voyage
            </Button>
          </div>
        )}

        {profile.role === 'broker' || profile.role === 'systemAdmin' ? (
          <>
            <Link to="/useraccount">
              <img alt="user-icon" src={UserIcon} />
            </Link>

            <Popover
              content={(
                <AcquistionToggle
                  onToggleAcquisitionMode={onToggleAcquisitionMode}
                  onLogout={onLogout}
                  acquisitionMode={preferences?.acquisitionMode}
                />
              )}
              placement="bottomRight"
              trigger="click"
            >
              <MoreOutlined
                style={{
                  fontSize: 20,
                  color: '#fff',
                  cursor: 'pointer',
                }}
              />
            </Popover>
          </>
        ) : (
          <div className="accountMenu">
            <Dropdown
              menu={{ items }}
              open={accountMenuOpen}
              onOpenChange={(open) => setAccountMenuOpen(open)}
              overlayClassName={styles.accountMenuOverlay}
              trigger={['hover', 'click']}
            >
              {accountMenuOpen ? (
                <img alt="user-icon-active" src={UserIconActive} />
              ) : (
                <img alt="user-icon" src={UserIcon} />
              )}
            </Dropdown>
          </div>
        )}
      </div>
    </AntHeader>
  );
};

const AcquistionToggle = ({
  onToggleAcquisitionMode,
  onLogout,
  acquisitionMode,
}) => (
  <div className={styles.acquisitionWrapper}>
    <div className={styles.switchWrapper}>
      <span>Acquistion mode</span>
      <Switch
        size="small"
        checked={acquisitionMode}
        defaultChecked={acquisitionMode}
        onChange={onToggleAcquisitionMode}
      />
    </div>
    <p>
      The acquisition mode allows you to switch between viewing acquisition
      fleets and client fleets across multiple sections of WAPA.
    </p>
    <button type="button" className={styles.signout} onClick={onLogout}>
      Sign out
    </button>
  </div>
);

export default Header;
