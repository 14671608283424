/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
  Layout, Row, Col, Spin,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { AuthContext } from '../../contexts/AuthProvider';
import './layouts.scss';

const { Content } = Layout;

const AuthLayout = ({ component: Component, location, ...rest }) => {
  const { loading, firstFactor } = useContext(AuthContext);

  if (loading) {
    return (
      <Spin
        indicator={<LoadingOutlined />}
        tip="Loading permissions..."
      >
        <div style={{ width: '100vw', height: '100vh' }} />
      </Spin>
    );
  }

  const createReturnUrl = () => {
    let returnUrl = {
      hasPreviousUrl: false,
      url: '',
    };
    if (location.search && location.search.includes('returnUrl')) {
      return {
        hasPreviousUrl: true,
        url: location.search,
      };
    }
    if (location.pathname && location.search) {
      returnUrl = {
        hasPreviousUrl: false,
        url: location.pathname + location.search,
      };
    }

    return returnUrl;
  };

  const returnUrl = createReturnUrl();

  const getReturnUrl = () => {
    if (returnUrl && returnUrl.url.includes('returnUrl')) {
      const redirectUrl = returnUrl.url.replace('?returnUrl=', '');
      return redirectUrl;
    }
    return '/';
  };
  // active session exists. redirect to dashboard or ..
  // todo: redirect logic (from emails, etc..)
  if (!firstFactor) {
    if (returnUrl.url) {
      return <Redirect to={getReturnUrl()} />;
    }
    return <Redirect to="/" />;
  }
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout className="auth-layout" style={{ minHeight: '100vh' }}>
          <Content>
            <Row>
              <Col span={8} offset={8} className="outer-wrapper">
                <div className="inner-wrapper">
                  <Component {...matchProps} />
                </div>
              </Col>
            </Row>
          </Content>
        </Layout>
      )}
    />
  );
};

export default AuthLayout;
