const apiBasePath = '/auth/';

export const websiteDomain = process.env.REACT_APP_SUPERTOKEN_WEBDOMAIN;

export const appInfo = {
  appName: 'WAPA',
  websiteDomain,
  apiDomain: process.env.REACT_APP_SUPERTOKEN_APIDOMAIN,
  apiBasePath,
};
