import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const Spinner = ({ size }) => (
  <div className="loading">
    <Spin indicator={<LoadingOutlined style={{ fontSize: size === 'big' ? 50 : 25 }} spin />} />
  </div>
);

export default Spinner;
