/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';

import { Layout, Spin } from 'antd';

import { Route, Redirect } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { pathOr } from 'ramda';
import { AuthContext } from '../../contexts/AuthProvider';
import Header from '../header/header';
import routes from '../../routes';
import { defaultTheme } from '../contants';

const { Content } = Layout;

const DefaultLayout = ({ component: Component, path, ...rest }) => {
  const {
    loading,
    firstFactor,
    secondFactor,
    profile,
    permissions,
  } = useContext(AuthContext);

  if (loading) {
    return (
      <Spin
        indicator={<LoadingOutlined />}
        tip="Loading permissions..."
      >
        <div style={{ width: '100vw', height: '100vh' }} />
      </Spin>
    );
  }
  if (firstFactor) {
    return (
      <Redirect to="/auth/" />
    );
  }
  if (secondFactor) {
    return (
      <Redirect to="/second-factor" />
    );
  }

  const checkPermission = () => {
    const currentRoute = routes.filter((route) => route.path.includes(path))[0];

    if (currentRoute && (!currentRoute.needsPermission || pathOr(true, [currentRoute.key, 'read'], permissions))) {
      return true;
    }
    return false;
  };

  const hasPermission = checkPermission();

  // redirect if user doesn't have read permission for that route
  if (!hasPermission) {
    return (
      <Redirect to="/" />
    );
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout style={{ minHeight: '100vh' }}>
          <Header />
          <Layout style={{ backgroundColor: defaultTheme.colors.neutralWhite }}>
            <Content style={{ margin: path === '/' || path.includes('dashboard') ? '0px' : '0px 16px' }}>
              <div style={{ padding: path === '/' || path.includes('dashboard') ? 0 : '32px 48px', minHeight: 360 }}>
                <Component
                  permissions={permissions}
                  role={profile.role || 'guest'}
                  {...matchProps}
                />
              </div>
            </Content>
          </Layout>
        </Layout>
      )}
    />
  );
};

export default DefaultLayout;
