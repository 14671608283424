import React from 'react';
import Spinner from './spinner';

const Loading = ({ pastDelay, error }) => {
  if (pastDelay) {
    return <Spinner size="big" />;
  }
  if (error) {
    console.error(error);
    return <div className="sorry"><h1>Sorry, there was a problem loading the page.</h1></div>;
  }

  return null;
};

export default Loading;
