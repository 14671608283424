export default [
  {
    key: 'map',
    label: 'Map',
    path: '/dashboard',
  },
  {
    key: 'voyages',
    label: 'Voyages',
    children: [
      {
        key: 'voyages',
        label: 'Voyages',
        path: '/voyages',
      },
      {
        key: 'calculations',
        label: 'Calculations',
        path: '/calculations',
      },
    ],
  },
  {
    key: 'declarations',
    label: 'Transits',
    path: '/declarations',
  },
  {
    key: 'assets',
    label: 'Assets',
    children: [
      {
        key: 'fleets',
        label: 'Fleets',
        path: '/fleets',
      },
      {
        key: 'vessels',
        label: 'Vessels',
        path: '/vessels',
      },
      {
        key: 'ports',
        label: 'Ports',
        path: '/ports',
      },
    ],
  },
  {
    key: 'administration',
    label: 'Administration',
    children: [
      {
        key: 'policies',
        label: 'Policies',
        path: '/policies',
      },
      {
        key: 'zones',
        label: 'Zones',
        path: '/zones',
      },
      {
        key: 'users',
        label: 'Users',
        path: '/users',
      },
      {
        key: 'archives',
        label: 'Archives',
        path: '/archives',
      },
    ],
  },
];
