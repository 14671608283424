import { defaultTheme } from '../components/contants';

const zoneTypes = {
  war: 'War',
  killAndRansom: 'K&R',
  warKillAndRansom: 'War & K&R',
  ice: 'Ice',
  us200: 'US-200',
  sanctions: 'Sanctions',
};

const zoneTypesEnum = {
  War: 'war',
  'K&R': 'killAndRansom',
  'War & K&R': 'warKillAndRansom',
  Ice: 'ice',
  'US-200': 'us200',
  Sanctions: 'sanctions',
};

const sorterOrderToQueryOrderDirection = {
  ascend: 'asc',
  descend: 'desc',
};

const zoneTypesArr = ['War', 'K&R', 'War & K&R', 'Ice', 'US-200', 'Sanctions'];

const voyageStatus = {
  draft: 'Draft',
  requestPending: 'Request pending',
  requestRejected: 'Request rejected',
  requestProcessing: 'Request processing',
  charterApprovalPending: 'Charter approval pending',
  quoted: 'Quoted',
  quoteRejected: 'Quote rejected',
  cancelled: 'Voyage cancelled',
  firmCall: 'Firm call',
  voyageInProgress: 'Voyage in progress',
  declarationInProgress: 'Declaration in progress',
  declarationPending: 'Declaration pending',
  declared: 'Declared',
  declarationRejected: 'Declaration rejected',
  // processingInvoice: 'Processing invoice',
  processingInvoice: 'Declaration approved',
  invoiced: 'Invoiced',
  invoiceRejected: 'Invoice rejected',
};

const statusEnum = [
  { value: 'draft', label: 'Draft', dotColor: defaultTheme.colors.neutralMain },
  { value: 'requestPending', label: 'Request Pending', dotColor: defaultTheme.colors.neutralMain },
  {
    value: 'requestRejected',
    label: 'Request Rejected',
    dotColor: defaultTheme.colors.accentRed,
  },
  {
    value: 'requestProcessing',
    label: 'Request Processing',
    dotColor: defaultTheme.colors.accentBlue,
  },
  {
    value: 'charterApprovalPending',
    label: 'Charter Approval Pending',
    dotColor: defaultTheme.colors.neutralMain,
  },
  { value: 'quoted', label: 'Quoted', dotColor: defaultTheme.colors.accentPurple },
  { value: 'quoteRejected', label: 'Quote Rejected', dotColor: defaultTheme.colors.accentRed },
  { value: 'cancelled', label: 'Cancelled', dotColor: defaultTheme.colors.accentRed },
  { value: 'firmCall', label: 'Firm Call', dotColor: defaultTheme.colors.accentBlue },
  {
    value: 'voyageInProgress',
    label: 'Voyage In Progress',
    dotColor: defaultTheme.colors.accentBlue,
  },
  {
    value: 'declarationInProgress',
    label: 'Declaration In Progress',
    dotColor: defaultTheme.colors.accentBlue,
  },
  {
    value: 'declarationPending',
    label: 'Declaration Pending',
    dotColor: defaultTheme.colors.neutralMain,
  },
  { value: 'declared', label: 'Declared', dotColor: defaultTheme.colors.accentOrange },
  {
    value: 'declarationRejected',
    label: 'Declaration Rejected',
    dotColor: defaultTheme.colors.accentRed,
  },
  {
    value: 'processingInvoice',
    label: 'Processing Invoice',
    dotColor: defaultTheme.colors.accentBlue,
  },
  { value: 'invoiced', label: 'Invoiced', dotColor: defaultTheme.colors.accentGreen },
  {
    value: 'invoiceRejected',
    label: 'Invoice Rejected',
    dotColor: defaultTheme.colors.accentRed,
  },
];

const generalConditions = {
  minFreeboard: 'Minimum Freeboard',
  minSpeed: 'Minimum Speed in HRA',
  bmpCompliant: 'BMP Compliant',
  registration: 'UKMTO/MSCHOA Registration',
  razorWire: 'Razor Wire or Similar',
  citadel: 'Citadel',
  safeMuster: 'Safe Muster Point',
  armedGuards: 'Armed guards',
  minGuards: 'Number of Guards',
  escortBoat: 'Navy Escort Boat',
  ammunition: 'Arms or Ammunition on Board as Cargo',
  connectionWithRussia: 'Connection with Russia',
};

const iceConditions = {
  iceCharts: 'Ice charts',
  iceThickness: 'Max. Ice Thickness',
  icePilots: 'Ice Pilots',
  iceBreakers: 'Icebreakers/Convoy',
  routingConditions: 'Routing requirements',
};

const defaultRolePermissions = {
  systemAdmin: {
    fleets: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    voyages: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    vessels: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    zones: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    policies: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    transits: {
      read: true, update: true, delete: undefined, restore: undefined, create: undefined,
    },
    users: {
      read: true, update: true, delete: true, create: true,
    },
    ports: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    calculations: {
      read: true, update: true, delete: true, create: true,
    },
  },
  broker: {
    fleets: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    voyages: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    vessels: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    zones: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    policies: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    transits: {
      read: true, update: true, delete: undefined, restore: undefined, create: undefined,
    },
    users: {
      read: false, update: false, delete: false, create: false,
    },
    ports: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    calculations: {
      read: true, update: true, delete: true, create: true,
    },
  },
  adminDept: {
    fleets: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    voyages: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    vessels: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    zones: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    policies: {
      read: true, update: true, delete: true, restore: true, create: true,
    },
    transits: {
      read: true, update: false, delete: undefined, restore: undefined, create: undefined,
    },
    users: {
      read: false, update: false, delete: false, create: false,
    },
    ports: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    calculations: {
      read: true, update: false, delete: false, create: false,
    },
  },
  claimsDept: {
    fleets: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    voyages: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    vessels: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    zones: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    policies: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    transits: {
      read: true, update: false, delete: undefined, restore: undefined, create: undefined,
    },
    users: {
      read: false, update: false, delete: false, create: false,
    },
    ports: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    calculations: {
      read: false, update: false, delete: false, create: false,
    },
  },
  guest: {
    fleets: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    voyages: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    vessels: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    zones: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    policies: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    transits: {
      read: true, update: false, delete: undefined, restore: undefined, create: undefined,
    },
    users: {
      read: false, update: false, delete: false, create: false,
    },
    ports: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    calculations: {
      read: false, update: false, delete: false, create: false,
    },
  },
  fleetOwner: {
    fleets: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    voyages: {
      read: true, update: true, delete: false, restore: true, create: true,
    },
    vessels: {
      read: true, update: true, delete: false, restore: false, create: false,
    },
    zones: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    policies: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    transits: {
      read: true, update: true, delete: undefined, restore: undefined, create: undefined,
    },
    users: {
      read: true, update: true, delete: true, create: true,
    },
    ports: {
      read: false, update: false, delete: false, restore: false, create: false,
    },
    calculations: {
      read: false, update: false, delete: false, create: false,
    },
  },
  operator: {
    fleets: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    voyages: {
      read: true, update: true, delete: false, restore: true, create: true,
    },
    vessels: {
      read: true, update: true, delete: false, restore: false, create: false,
    },
    zones: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    policies: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    transits: {
      read: true, update: true, delete: undefined, restore: undefined, create: undefined,
    },
    users: {
      read: false, update: false, delete: false, create: false,
    },
    ports: {
      read: false, update: false, delete: false, restore: false, create: false,
    },
    calculations: {
      read: false, update: false, delete: false, create: false,
    },
  },
  insurer: {
    fleets: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    voyages: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    vessels: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    zones: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    policies: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    transits: {
      read: true, update: false, delete: false, restore: false, create: false,
    },
    users: {
      read: false, update: false, delete: false, create: false,
    },
    ports: {
      read: false, update: false, delete: false, restore: false, create: false,
    },
    calculations: {
      read: false, update: false, delete: false, create: false,
    },
  },
};

const UsersPage = {
  OVERVIEW: {
    key: 'overview',
    path: '/users',
  },
  CREATE: {
    key: 'addUser',
    path: '/users/new',
  },
  EDIT: {
    key: 'editUser',
  },
  LOADING: {
    key: 'loading',
  },
};

export {
  zoneTypes,
  sorterOrderToQueryOrderDirection,
  zoneTypesArr,
  voyageStatus,
  zoneTypesEnum,
  statusEnum,
  generalConditions,
  iceConditions,
  defaultRolePermissions,
  UsersPage,
};
