const { gql } = require('@apollo/client');

const GET_CALCULATIONS = gql`
  query GetCalculationTemplates($filters: CalculationTemplateFilters, $paginator: Paginator, $sorter: Sorter, $search: String) {
    getCalculationTemplates(filters: $filters, paginator: $paginator, sorter: $sorter, search: $search) {
      data {
        id
        title
        description
        fleet {
          id
          name
        }
        versions {
          filename
          path
          uid
          url
        }
        latestVersion
        type
        parent
        createdBy {
          firstname
          lastname
          uid
        }
        createdAt
        updatedAt
      }
      status
      message
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
    }
  }
`;

const ARCHIVE_CALCULATION = gql`
mutation ArchiveCalculationTemplate($id: ID!) {
  archiveCalculationTemplate(id: $id) {
    message
    status
  }
}`;

const UPDATE_CALCULATION = gql`
mutation EditCalculationTemplate($editCalculationTemplateData2: CalculationTemplateEditInput!) {
  editCalculationTemplate(data: $editCalculationTemplateData2) {
    data {
      id
      title
      description
      versions {
        filename
        path
        uid
        url
        version
        computed {
          results {
            type
            data
            createdAt
            updatedAt
          }
        }
        uploadedBy {
          firstname
          lastname
          uid
        }
        createdAt
        updatedAt
      }
      latestVersion
      type
      parent
      createdBy {
        firstname
        lastname
        uid
      }
      createdAt
      updatedAt
    }
    warnings
    status
    message
  }
}`;

const CREATE_CALCULATION = gql`
mutation CreateCalculationTemplate($createCalculationTemplateData2: CalculationTemplateCreateInput!) {
  createCalculationTemplate(data: $createCalculationTemplateData2) {
    message
    status
  }
}`;

const CREATE_IF_NOT_EXISTS_CALCULATION = gql`
mutation CreateCalculationTemplate($data: CalculationTemplateCreateInput!) {
  createCalculationTemplate(data: $data) {
    data {
      id
      title
      description
      versions {
        filename
        path
        uid
        url
        version
        computed {
          results {
            type
            data
            createdAt
            updatedAt
          }
        }
        uploadedBy {
          firstname
          lastname
          uid
        }
        createdAt
        updatedAt
      }
      latestVersion
      type
      parent
      createdBy {
        firstname
        lastname
        uid
      }
      createdAt
      updatedAt
    }
    status
    message
    warnings
  }
}`;

const GET_CALCULATION = gql`
query GetCalculationTemplate($getCalculationTemplateId: ID!) {
  getCalculationTemplate(id: $getCalculationTemplateId) {
    data {
      id
      title
      description
      versions {
        filename
        path
        uid
        url
        version
        computed {
          results {
            type
            data
            createdAt
            updatedAt
          }
        }
        uploadedBy {
          firstname
          lastname
          uid
        }
        createdAt
        updatedAt
      }
      latestVersion
      type
      parent
      createdBy {
        firstname
        lastname
        uid
      }
      createdAt
      updatedAt
    }
    status
    message
  }
}`;

const CHECK_CALCULATION = gql`
query CheckCalculationTemplate($checkCalculationTemplateData2: CalculationTemplateCheckInput!) {
  checkCalculationTemplate(data: $checkCalculationTemplateData2) {
    message
    status
    warning
  }
}`;

export {
  GET_CALCULATIONS,
  CREATE_CALCULATION,
  CREATE_IF_NOT_EXISTS_CALCULATION,
  ARCHIVE_CALCULATION,
  GET_CALCULATION,
  CHECK_CALCULATION,
  UPDATE_CALCULATION,
};
