import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';

import Session from 'supertokens-auth-react/recipe/session';
import { appInfo } from './appInfo';

const frontendConfig = () => {
  console.log('env', appInfo);
  return {
    appInfo,
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [
      EmailPassword.init({
        signInAndUpFeature: {
          disableDefaultUI: true,
        },
        resetPasswordUsingTokenFeature: {
          disableDefaultUI: true,
        },
        // The user will be taken to the custom path when they click on forgot password.
        getRedirectionURL: async (context) => {
          if (context.action === 'RESET_PASSWORD') {
            return '/reset-password';
          }
          return '/';
        },
      }),
      // ThirdPartyEmailPassword.init({
      //     signInAndUpFeature: {
      //         providers: [Google.init()],
      //     },
      // }),
      // ThirdParty.init({
      //     signInAndUpFeature: {
      //         providers: [Github.init(), Google.init()],
      //     },
      // }),

      Session.init({}),
    ],
  };
};

export default frontendConfig;
