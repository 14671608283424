/* eslint-disable no-unreachable */
import React, { useEffect } from 'react';
import styles from './LogoAnimation.module.scss';

const LogoAnimation = (props) => {
  // eslint-disable-next-line no-loss-of-precision
  const imageRatio = 1.1034482758620689655172413793103;
  const { style, onFinish } = props;
  let { width, height } = props;
  if (width && !height) height = width / imageRatio;
  if (height && !width) width = height * imageRatio;

  useEffect(() => {
    function applyDrawInOutAnimation(
      _svgElement,
      animatedElements,
      secondsStroke,
      secondsFill,
      _config = {},
      callback = () => {},
    ) {
      const defaultConfig = {
        fadein: true,
        ripple: true,
        callbackOffsetPercent: 0,
        callbackOffsetValue: 0,
      };

      const config = { ...defaultConfig, ..._config };
      console.log('[LOGO ANIMATION] applyDrawInOutAnimation.start');
      // return;
      // Get all the path elements within the SVG
      const paths = animatedElements.querySelectorAll('path');
      const clonePaths = [];
      const secondsTotal = Number.parseFloat(secondsFill) + Number.parseFloat(secondsStroke);
      const svgElement = _svgElement;

      console.log(`[LOGO ANIMATION] Cloning paths: ${paths.length}`);
      paths.forEach((path) => {
        const clone = path.cloneNode();
        animatedElements.appendChild(clone);
        clonePaths.push(clone);
      });

      // Updating style
      paths.forEach((_path, i) => {
        const path = _path;
        const clonePath = clonePaths[i];

        path.style.strokeWidth = 0.5;
        clonePath.style.fill = path.getAttribute('fill');

        if (config.fadein) {
          console.log(
            `[LOGO ANIMATION] Updating for fadein with fillcolor: ${path.getAttribute(
              'fill',
            )} and opacity: 0`,
          );
          path.style.stroke = path.getAttribute('fill');
          clonePath.style.opacity = 0;
        } else {
          console.log('[LOGO ANIMATION] Updating for fadeout');
          path.style.fill = 'transparent';
          clonePath.style.opacity = 1;
        }
      });

      // Set the initial stroke-dasharray and stroke-dashoffset for each path
      paths.forEach((_path) => {
        const path = _path;
        const length = path.getTotalLength();

        path.style.strokeDasharray = length;

        if (config.fadein) {
          path.style.strokeDashoffset = length;
        } else {
          path.style.strokeDashoffset = 0;
        }
      });

      if (config.ripple) {
        // resets first
        svgElement.querySelectorAll('#rippleElementBase')[0].style.animation = '';
        svgElement.querySelectorAll('#rippleElementBase')[0].setAttribute('r', '0px');

        console.log('[LOGO ANIMATION] Applying ripple effect', svgElement.querySelectorAll('#rippleElementBase')[0]);
        const rippleAnimationDuration = Math.min(secondsTotal);
        const rippleAnimationCssName = styles.rippleAnimation;
        const rippleAnimation = `${rippleAnimationCssName} ${rippleAnimationDuration}s ease-in-out forwards`;

        setTimeout(() => {
          svgElement.querySelectorAll('#rippleElementBase')[0].setAttribute('r', `${width / 2}px`);
          svgElement.querySelectorAll('#rippleElementBase')[0].style.animation = rippleAnimation;
        }, 0);
      }

      // Trigger the animation by gradually reducing the stroke-dashoffset
      paths.forEach((_path, i) => {
        const path = _path;
        const clonePath = clonePaths[i];
        const length = path.getTotalLength();

        if (config.fadein) {
          path.style.transition = `stroke-dashoffset ${secondsStroke}s ease-in-out`;
          clonePath.style.transition = `opacity ${secondsFill}s ease-in-out ${secondsStroke}s`;

          path.style.strokeDashoffset = 0;
          clonePath.style.opacity = 1;
        } else {
          // filling out first
          path.style.opacity = 0;

          clonePath.style.transition = `opacity ${secondsFill}s ease-in-out`;
          path.style.transition = `stroke-dashoffset ${secondsStroke}s ease-in-out ${secondsFill}s`;

          path.style.strokeDashoffset = length;
          clonePath.style.opacity = 0;
        }
      });

      setTimeout(() => {
        paths.forEach((_path, i) => {
          const path = _path;
          const clonePath = clonePaths[i];

          if (config.fadein) {
            path.style.fill = path.getAttribute('fill');
          }
          clonePath.remove();
        });
      }, secondsTotal * 1000);

      setTimeout(() => {
        callback();
      }, ((secondsTotal * (1 + config.callbackOffsetPercent)) + config.callbackOffsetValue) * 1000);
    }

    /**
     * RUNNNING
     */

    const svgElement = document.getElementById('svgElement');
    const frontElements = svgElement.querySelectorAll('g#front')[0];
    const behindElements = svgElement.querySelectorAll('g#behind')[0];
    // const paths = svgElement.querySelectorAll('path');

    /**
     * DURATIONS OF ANIMATION PARTS
     */
    const stroke = 0.6;
    const fill = 0.4;
    const bounce = 2;
    const hold = 0;
    const coeffReduc = 2;

    const runAnimation = () => {
      console.log('[LOGO ANIMATION] runAnimation.start');
      // eslint-disable-next-line max-len
      applyDrawInOutAnimation(svgElement, frontElements, String(stroke), String(fill), {
        fadein: true,
        ripple: true,
        callbackOffsetPercent: -0.3,
      }, () => {
        // eslint-disable-next-line max-len
        applyDrawInOutAnimation(svgElement, behindElements, String(stroke), String(fill), {
          fadein: true,
          ripple: false,
        }, () => {
          // eslint-disable-next-line no-unused-vars
          const logoBounceAnimationCssName = styles.logoBounceAnimation;
          // eslint-disable-next-line no-unused-vars
          const logoRotateAnimationCssName = styles.logoRotateAnimation;
          // eslint-disable-next-line no-unused-vars
          const logoBounceRotateAnimationCssName = styles.logoBounceRotateAnimation;

          svgElement.style.animation = `${logoBounceRotateAnimationCssName} ${bounce}s ease-in-out forwards`;
          setTimeout(() => {
            // eslint-disable-next-line max-len
            applyDrawInOutAnimation(svgElement, behindElements, String(stroke / coeffReduc), String(fill / coeffReduc), {
              fadein: false,
              ripple: true,
              callbackOffsetPercent: -1, // execute callback immediately
            }, () => {
              // eslint-disable-next-line max-len
              applyDrawInOutAnimation(svgElement, frontElements, String(stroke / coeffReduc), String(fill / coeffReduc), {
                fadein: false,
                ripple: false,
              }, () => {
                svgElement.style.animation = '';
                onFinish();
              });
            });
          }, (bounce + hold) * 1000);
        });
      });
    };

    runAnimation();
  }, []);

  return (
    <div
      id="svg-container"
      className={styles.svgContainer}
      style={{ display: 'flex', alignItems: 'center', height: '100%' }}
    >
      <svg
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        id="svgElement"
        className={styles.svgElement}
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <g transform="matrix(1, 0, 0, 1, -8.884712, -5.462138)">
          <g id="anims" transform="translate(50, 50)">
            <circle id="rippleElementBase" r="1em" />
          </g>
          <g>
            <radialGradient
              id="SVGID_1_"
              cx="43.9694"
              cy="50.2623"
              r="22.996"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" style={{ stopColor: '#008BD2' }} />
              <stop offset="0.4909" style={{ stopColor: '#0075BF' }} />
              <stop offset="0.7697" style={{ stopColor: '#004F9F' }} />
              <stop offset="1" style={{ stopColor: '#143A84' }} />
            </radialGradient>
            <radialGradient
              id="SVGID_00000018203813943505511110000004296320448356966274_"
              cx="67.0532"
              cy="49.2396"
              r="17.7195"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" style={{ stopColor: '#008BD2' }} />
              <stop offset="0.4909" style={{ stopColor: '#0075BF' }} />
              <stop offset="0.7697" style={{ stopColor: '#004F9F' }} />
              <stop offset="1" style={{ stopColor: '#143A84' }} />
            </radialGradient>

            <g id="logo-svg-elements">
              <g id="behind">
                <path
                  d="M 45.02,78.4 27.66,78.4 40.81,22.12 60.27,28.43 z"
                  className="st1"
                  style={{ fill: 'transparent' }}
                  fill="url(#SVGID_1_)"
                />
                <path
                  d="M 69.16,61.86 52.09,69.34 67.46,29.14 82.01,34.98 z"
                  style={{ fill: 'transparent' }}
                  fill="url(#SVGID_00000018203813943505511110000004296320448356966274_)"
                />
              </g>

              <g id="front">
                <path
                  className="st3"
                  d="M40.22,24.27l10.6,44.07c0.69,2.88,4.63,4.4,7.08,2.74l10.22-6.94c1.14-0.77,1.64-2.07,1.32-3.4l-7.71-32.07 c-0.32-1.34-1.42-2.51-2.88-3.07l-13.11-5.06C42.59,19.31,39.53,21.39,40.22,24.27z"
                  style={{ fill: 'transparent' }}
                  fill="rgb(0, 154, 222)"
                />
                <path
                  className="st3"
                  d="M10.41,12.75l17.23,71.32c0.7,2.88,4.63,4.4,7.08,2.74l10.14-6.88c1.14-0.77,1.64-2.07,1.32-3.41 L31.82,17.11c-0.32-1.34-1.42-2.51-2.88-3.07L15.93,9.01C12.78,7.79,9.72,9.87,10.41,12.75z"
                  style={{ fill: 'transparent' }}
                  fill="rgb(0, 154, 222)"
                />
                <path
                  d="M 73.57,60.44 100.95,41.86 67.18,28.81 80.02,42.58 z"
                  className="st4"
                  style={{ fill: 'transparent' }}
                  fill="rgb(212, 19, 103)"
                />
              </g>
            </g>
          </g>
          <g>
            <g />
          </g>
        </g>
      </svg>
    </div>
  );
};

/**
 * Passes the props, and returns the LogoAnimation component
 * When the animation is finished, destroys the LogoAnimation component
 * and makes re-creates another one.
 */
const LogoAnimationWrapper = (props) => {
  const { loop, loopDelay, onFinish: _onFinish } = props;
  const [logoComponent, setLogoComponent] = React.useState();

  useEffect(() => {
    const onFinish = () => {
      if (_onFinish) _onFinish();
      if (loop === true) {
        setTimeout(() => {
          setLogoComponent(null);
        }, (loopDelay || 0) * 1000);
      }
    };

    if (!logoComponent) {
      setLogoComponent(<LogoAnimation {...props} onFinish={onFinish} />);
    }
  }, [logoComponent]);

  return logoComponent;
};

export default LogoAnimationWrapper;
