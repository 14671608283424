import { gql } from '@apollo/client';

const GET_ZONES_FOR_PORTS_OVERVIEW = gql`
  query GetZonesForPorts {
    getZones {
      data {
        name
        id
      }
      message
      status
    }
  }
`;

const GET_OFFICIAL_PORTS = gql`
query GetPorts($paginator: Paginator, $sorter: Sorter, $filters: PortFilter, $search: String) {
  getPorts(paginator: $paginator, sorter: $sorter, filters: $filters, search: $search) {
    data {
      id
      name
      country
      alias
      coordinates {
        lat {
          direction
          value
        }
        lng {
          direction
          value
        }
      }
      zones
    }
    paginationInfo {
      totalItems
      limit
      currentPage
      totalPages
      nextPage
      prevPage
      hasNextPage
      hasPrevPage
    }
    status
    message
  }
}
`;

const GET_ALL_PORTS = gql`
  query GetAllPorts {
    getAllPorts {
      data {
        country
        id
        isSuggested
        name
      }
      message
      status
    }
  }
`;

const GET_SUGGESTED_PORTS = gql`
  query GetSuggestPorts {
    getSuggestedPorts {
      data {
        id
        name
        country
        coordinates {
          lat {
            direction
            value
          }
          lng {
            direction
            value
          }
        }
      }
      status
      message
    }
  }
`;

const GET_ALIASES = gql`
  query GetAliases {
    getAliases {
      data {
        name
        alias
        type
      }
      status
      message
    }
  }
`;

const GET_APPLICABLE_ZONES = gql`
  query GetApplicableZonesForPort($coordinates: CoordsInput!) {
    getApplicableZonesForPort(coordinates: $coordinates) {
      status
      message
      data
    }
  }
`;

const GET_PORT = gql`
  query GetPort($getPortId: ID!) {
    getPort(id: $getPortId) {
      data {
        id
        name
        country
        coordinates {
          lat {
            direction
            value
          }
          lng {
            direction
            value
          }
        }
        zones
        alias
      }
      status
      message
    }
  }
`;

const CREATE_PORT = gql`
  mutation CreatePort($portDetails: PortInput!) {
    createPort(portDetails: $portDetails) {
      status
      message
      data {
        zones
        name
        id
        country
        coordinates {
          lat {
            value
            direction
          }
          lng {
            value
            direction
          }
        }
      }
    }
  }
`;

const UPDATE_PORT = gql`
  mutation UpdatePort($updatePortId: ID!, $portDetails: PortInput!) {
    updatePort(id: $updatePortId, portDetails: $portDetails) {
      data {
        id
        name
        country
        coordinates {
          lat {
            direction
            value
          }
          lng {
            direction
            value
          }
        }
        zones
        alias
      }
      message
      status
    }
  }
`;

const REMOVE_PORT = gql`
  mutation RemovePort($removePortId: ID!) {
    removePort(id: $removePortId) {
      status
      message
    }
  }
`;

const MATCH_PORT = gql`
  mutation Mutation($portDetails: MatchPortInput!) {
    matchExistingPort(portDetails: $portDetails) {
      message
      status
    }
  }
`;

const ADD_SUGGESTED_PORT = gql`
  mutation AddSuggestedPort($portSuggestedInput: PortSuggestedInput!) {
    addSuggestedPort(portSuggestedInput: $portSuggestedInput) {
      message
      status
    }
  }
`;

export {
  GET_ZONES_FOR_PORTS_OVERVIEW,
  GET_OFFICIAL_PORTS,
  GET_ALL_PORTS,
  GET_SUGGESTED_PORTS,
  GET_ALIASES,
  GET_APPLICABLE_ZONES,
  GET_PORT,
  CREATE_PORT,
  UPDATE_PORT,
  REMOVE_PORT,
  MATCH_PORT,
  ADD_SUGGESTED_PORT,
};
