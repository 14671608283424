function getQueryErrorFromError(error) {
  /**
   * The error object has the following error properties:
   *  - graphQLErrors: An array of errors produced by the GraphQL server.
   *  - protocolErrors: An array of errors produced by the transport layer.
   *  - clientErrors: An array of errors produced by the Apollo Client.
   *  - networkError: A network error produced by the transport layer.
   *  - message
   */

  if (error?.graphQLErrors?.length > 0) {
    // eslint-disable-next-line max-len
    const graphQLError = Array.isArray(error.graphQLErrors) ? error.graphQLErrors[0] : error.graphQLErrors;

    if (graphQLError.extensions?.response?.body?.message) {
      return graphQLError.extensions.response.body.message;
    }
    return graphQLError.message;
  }

  return error.message;
}

export default function getQueryErrorMessage(results) {
  if (results instanceof Error) {
    return getQueryErrorFromError(results);
  }

  if (typeof results === 'string') {
    return results;
  }

  let errorMessage;

  if (!results.data?.checkCalculationTemplate?.status) {
    errorMessage = results.data?.checkCalculationTemplate?.message;
  }

  if (results.error) {
    // eslint-disable-next-line no-param-reassign
    if (!results.errors) results.errors = [];
    results.errors.push(results.error);
  }

  if (results.errors) {
    let error = Array.isArray(results.errors) ? results.errors[0] : results.errors;
    errorMessage = error?.message;

    if (error?.extensions?.response?.body?.message) {
      errorMessage = error.extensions.response.body.message;
    }

    if (error?.graphQLErrors) {
      // eslint-disable-next-line prefer-destructuring
      error = Array.isArray(error.graphQLErrors) ? error.graphQLErrors[0] : error.graphQLErrors;
      if (error?.extensions?.response?.body?.message) {
        errorMessage = error.extensions.response.body.message;
      }
    }
  }

  return errorMessage;
}
