import { gql } from '@apollo/client';

const GET_VOYAGES = gql`
  query getVoyages(
    $paginator: Paginator!
    $sorter: Sorter!
    $search: String
    $filters: VoyageFilter
  ) {
    getVoyages(
      paginator: $paginator
      sorter: $sorter
      search: $search
      filters: $filters
    ) {
      message
      data {
        id
        vessel
        vesselName
        fleetName
        voyageId
        startDate
        endDate
        status
      }
      status
      paginationInfo {
        totalItems
        limit
        currentPage
        totalPages
        nextPage
        prevPage
        hasNextPage
        hasPrevPage
      }
    }
  }
`;

export const CREATE_VOYAGE = gql`
  mutation CreateVoyage($voyageDetails: VoyageInput!) {
    createVoyage(voyageDetails: $voyageDetails) {
      message
      status
      voyageId
    }
  }
`;

export const UPDATE_VOYAGE_REQUEST = gql`
  mutation UpdateVoyageRequest($voyageId: ID!, $voyageDetails: VoyageInput!) {
    updateVoyageRequest(voyageId: $voyageId, voyageDetails: $voyageDetails) {
      message
      status
    }
  }
`;

export const GET_VOYAGE = gql`
  query GetVoyage($voyageId: ID!) {
    getVoyage(voyageId: $voyageId) {
      data {
        id
        vessel
        voyageId
        startDate
        endDate
        route {
          id
          routeTypeId
          type
          entryDate
          exitDate
          entryType
          exitType
          subEntries
          conditions {
            type
            value
          }
          policies
          validDays
          grossPremium
          discount
          id
        }
        declarations {
          declarationTypeId
          type
          addedByRole
          subEntries
          entryLatitude
          entryLongitude
          exitLatitude
          exitLongitude
          entryTimestamp
          exitTimestamp
          entryType
          exitType
          isInvalid
          id
        }
        documents {
          filename
          path
          uid
        }
        currency
        netCommission
        hasCoBroker
        coBrokerCommission
        coBrokerName
        status
        statusHistory {
          status
          timestamp
        }
        rejectReason
      }
    }
  }
`;

export const SAVE_VOYAGE_QUOTATION = gql`
  mutation SaveVoyageQuotation(
    $voyageId: ID!
  ) {
    saveVoyageQuotation(voyageId: $voyageId) {
      message
      status
    }
  }
`;

export const SAVE_VOYAGE_DECLARATION = gql`
  mutation SaveVoyageDeclaration(
    $voyageId: ID!
    $declarationDetails: [DeclarationInput!]!
    $declarationsToBeRemoved: [ID]
  ) {
    saveVoyageDeclaration(
      voyageId: $voyageId
      declarationDetails: $declarationDetails
      declarationsToBeRemoved: $declarationsToBeRemoved
    ) {
      status
      message
    }
  }
`;

export const UPDATE_VOYAGE = gql`
  mutation UpdateVoyage(
    $voyageId: ID!
    $voyageDetails: VoyageInputOptional!
    $declarationDetails: [DeclarationInputOptional]!
    $declarationsToBeRemoved: [ID]
  ) {
    updateVoyage(
      voyageId: $voyageId
      voyageDetails: $voyageDetails
      declarationDetails: $declarationDetails
      declarationsToBeRemoved: $declarationsToBeRemoved
    ) {
      message
      status
    }
  }
`;

export const GET_EXISTING_VOYAGE_BY_ID = gql`
  query GetExistingVoyage($voyageId: String!) {
    getExistingVoyage(voyageId: $voyageId) {
      data
      status
      message
    }
  }
`;

export const CREATE_VOYAGE_AS_DRAFT = gql`
  mutation CreateVoyageAsDraft($voyageDetails: VoyageInputOptional!) {
    createVoyageAsDraft(voyageDetails: $voyageDetails) {
      message
      status
    }
  }
`;

export const BULK_UPLOAD_QUOTES = gql`
  mutation BulkImportVoyages($voyages: [BulkVoyageInput!]!) {
    bulkImportVoyages(voyages: $voyages) {
      status
      message
    }
  }
`;

export const ARCHIVE_VOYAGE = gql`
  mutation ArchiveVoyage($voyageId: ID!) {
    archiveVoyage(voyageId: $voyageId) {
      message
      status
    }
  }
`;

export const APPROVE_REJECT_QUOTATION = gql`
  mutation ApproveOrRejectQuote(
    $voyageId: ID!
    $action: QuoteAction!
    $reason: String
  ) {
    approveOrRejectQuote(
      voyageId: $voyageId
      action: $action
      reason: $reason
    ) {
      message
      status
    }
  }
`;

export const APPROVE_REJECT_DECLARATION = gql`
  mutation ApproveOrRejectDeclaration(
    $voyageId: ID!
    $action: QuoteAction!
    $reason: String
  ) {
    approveOrRejectDeclaration(
      voyageId: $voyageId
      action: $action
      reason: $reason
    ) {
      message
      status
    }
  }
`;

export const APPROVE_REJECT_VOYAGEREQUEST = gql`
  mutation ApproveOrRejectVoyageRequest(
    $voyageId: ID!
    $action: QuoteAction!
    $reason: String
  ) {
    approveOrRejectVoyageRequest(
      voyageId: $voyageId
      action: $action
      reason: $reason
    ) {
      message
      status
    }
  }
`;

export const CANCEL_VOYAGE = gql`
  mutation CancelVoyage($voyageId: ID!) {
    cancelVoyage(voyageId: $voyageId) {
      message
      status
    }
  }
`;

export { GET_VOYAGES };
