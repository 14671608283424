/* eslint-disable import/no-import-module-exports */
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import { ApolloProvider } from '@apollo/client';
import SuperTokensReact, { SuperTokensWrapper } from 'supertokens-auth-react';
import { ConfigProvider } from 'antd';
import configureStore from './store';
import history from './utils/history';
import App from './app';
import { unregister } from './registerServiceWorker';
import client from './services/client';
import { AuthProvider } from './contexts';
import frontendConfig from './utils/superToken';
import { themes } from './components/contants';
import QueryCacheMonitorProvider from './contexts/QueryCacheMonitorProvider';

Sentry.init({
  dsn: 'https://ba7865c20c316390d5b65b7244de108f@o147211.ingest.us.sentry.io/4506982200508416',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: process.env.REACT_APP_ENV !== 'development' ? 1.0 : 0,
  environment: process.env.REACT_APP_ENV === 'production' ? 'production' : 'staging',
});

SuperTokensReact.init(frontendConfig());

const store = configureStore();

const container = document.getElementById('root');

const root = createRoot(container);
const customThemes = themes();

const render = (Component) => root.render(
  <SuperTokensWrapper>

    <ApolloProvider client={client}>
      <AuthProvider>
        <QueryCacheMonitorProvider>
          <Provider store={store}>
            <Router history={history}>
              <ConfigProvider
                theme={customThemes}
              >
                <Component />
              </ConfigProvider>
            </Router>
          </Provider>
        </QueryCacheMonitorProvider>
      </AuthProvider>
    </ApolloProvider>
  </SuperTokensWrapper>,
);

if (module.hot) {
  module.hot.accept('./app', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./app').default;
    render(NextApp);
  });
}

// store.firebaseAuthIsReady.then(() =>
render(App);
// )

unregister();
