/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
} from 'react';
import { BackgroundQueryCoordinator } from '../utils/hooks/BackgroundQueryCoordinator';

const QueryCacheMonitorContext = createContext({});

export const useQueryCacheMonitor = () => useContext(QueryCacheMonitorContext);

const QueryCacheMonitorProvider = ({ children }) => {
  // value toggled when the cache is changed
  const [onCacheChange, setOnCacheChange] = useState({ flags: [] });

  useEffect(() => {
    BackgroundQueryCoordinator.addExpireListener((flags) => {
      console.log('CacheListener.callback.onCacheChange', 'expiredFlag', flags);
      setOnCacheChange(() => ({
        flags,
      }));
    });
  }, []);

  return (
    <QueryCacheMonitorContext.Provider
      value={{
        onCacheChange,
      }}
    >
      {children}
    </QueryCacheMonitorContext.Provider>
  );
};

export default QueryCacheMonitorProvider;
export { QueryCacheMonitorContext };
