/* eslint-disable react/jsx-props-no-spreading */
import './components/common.scss';
import './components/icons/flaticon.css';
import { Switch, Redirect } from 'react-router-dom';

import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Card, Flex, Result, Spin, Tag,
} from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';
import { pathOr } from 'ramda';
import layouts from './components/layouts';
import { AuthContext } from './contexts/AuthProvider';
import routes from './routes';
import { defaultRolePermissions } from './utils/enums';
import LogoAnimation from './components/common-ui/LogoAnimation';

const StatusPageCards = ({ pageId }) => {
  const [componentsStatus, setComponentsStatus] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch(`https://${pageId}.statuspage.io/api/v2/components.json`);
        if (!response.ok) {
          throw new Error('Failed to fetch status');
        }
        const data = await response.json();
        setComponentsStatus(data.components);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching status:', error);
        setLoading(false);
      }
    };

    fetchStatus();
  }, [pageId]);

  return (
    <Card title="System status" style={{ width: '100%' }}>
      {componentsStatus.map((component) => (
        <Flex justify="space-between" align="flex-start" style={{ margin: 5 }}>
          <div>{component.name}</div>
          <Tag color={component.status === 'operational' ? 'green' : 'red'}>{component.status}</Tag>
        </Flex>
      ))}
      {loading && <Spin />}
    </Card>
  );
};

/**
 * Only play the animation once every 24 hours
 */

let hasBeenPlayedInTheLast24hours = localStorage.getItem('hasBeenPlayedInTheLast24hours');
if (!hasBeenPlayedInTheLast24hours) {
  localStorage.setItem('hasBeenPlayedInTheLast24hours', JSON.stringify({ playedAt: new Date().getTime() }));
}

// eslint-disable-next-line max-len
if (hasBeenPlayedInTheLast24hours && new Date().getTime() - JSON.parse(hasBeenPlayedInTheLast24hours).playedAt > 1000 * 60 * 60 * 24) {
  localStorage.removeItem('hasBeenPlayedInTheLast24hours');
  hasBeenPlayedInTheLast24hours = false;
}

const App = () => {
  const { errorOccured, loading, profile } = useContext(AuthContext);
  // eslint-disable-next-line max-len
  const [hasDisplayedAnimationAtLeastOnce, setHasDisplayedAnimationAtLeastOnce] = useState(hasBeenPlayedInTheLast24hours);

  const getRoute = (route) => {
    const Layout = layouts[route.layout] || layouts.DefaultLayout;
    return (
      <Layout {...route} />

    );
  };

  if (errorOccured) {
    return (
      <>
        <Result
          status="error"
          title="Initialization failed"
          subTitle={(
            <center>
              <div style={{ maxWidth: 400 }}>
                An error occured while initializing the application. Try refreshing the page.
                If the problem persists, please contact
                {' '}
                <a href="mailto:broking.hml@vanbreda-marine.com">support</a>
                .
              </div>
            </center>
          )}
          extra={(
            <Button type="primary" key="console" href="https://wapa.statuspage.io" target="_blank">
              See WAPA status page
            </Button>
          )}
          style={{
            padding: 24,
          }}
        />
        <center>
          <div style={{ maxWidth: 500 }}>
            <StatusPageCards pageId="wapa" />
          </div>
        </center>
      </>
    );
  }

  if (!hasDisplayedAnimationAtLeastOnce || loading) {
    return (
      <Spin
        indicator={(
          // <LoadingOutlined style={{ fontSize: 24 }} spin />
          <LogoAnimation
            width={100}
            height={100}
            loop
            loopDelay={1}
            style={{ marginBottom: 100 }}
            onFinish={() => setHasDisplayedAnimationAtLeastOnce(true)}
          />
        )}
        tip="Initializing..."
      >
        <div style={{ width: '100vw', height: '100vh' }} />
      </Spin>
    );
  }

  // const oldRolename = roles.filter((role) => profile.role === role.keyNew)[0].key;
  const permissions = defaultRolePermissions[profile.role];

  return (
    <div className="app">
      <Switch>
        {routes.filter((route) => {
          if (route && (!route.needsPermission || pathOr(true, [route.key, 'read'], permissions))) return true;
          return false;
        }).map((route) => getRoute(route))}
        <Redirect path="*" to="/" />
      </Switch>
    </div>
  );
};

export default App;
